/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import '@newdaycards/ownbrands-library-cookieconsent/build/main.css'
import './src/styles/marbles.scss'
import appInsights from './src/utils/appInsights'

import { BreadCrumbProvider } from './breadcrumbprovider'

export const wrapRootElement = ({ element }) => (
  <BreadCrumbProvider>{element}</BreadCrumbProvider>
)

export const onInitialClientRender = () => {
  var telemetryInitializer = (envelope) => {
    envelope.data.ApplicationName = process.env.APPLICATION_NAME
    envelope.data.Brand = 'MARBLES'
  }

  appInsights.addTelemetryInitializer(telemetryInitializer)
}

export const onRouteUpdate = () => {
  appInsights.trackPageView()
}
