import React, { useState } from 'react'
import PropTypes from 'prop-types'

export const BreadcrumbContext = React.createContext({
  crumbs: null,
  updateCrumbs: () => {},
})

export const BreadCrumbProvider = ({ children }) => {
  const [list, setList] = useState()

  function updateCrumbs(items) {
    if (Array.isArray(items)) {
      setList(items)
    }
  }

  return (
    <BreadcrumbContext.Provider value={{ crumbs: list, updateCrumbs }}>
      {children}
    </BreadcrumbContext.Provider>
  )
}

BreadCrumbProvider.propTypes = {
  children: PropTypes.node,
}
